import { useEffect, useState } from 'react';
import Loader from 'react-loaders';
import AnimatedLetters from '../components/AnimatedLetters';
import emailjs from 'emailjs-com'; // Import EmailJS
import './index.scss'; // Ensure this path is correct

const Contact = () => {
  const [letterClass, setLetterClass] = useState('text-animate');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const contactArray = ['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'M', 'e'];

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 4000);
    return () => clearTimeout(timeout); // Clean up the timeout
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { REACT_APP_EMAILJS_SERVICE_ID, REACT_APP_EMAILJS_TEMPLATE_ID, REACT_APP_EMAILJS_USER_ID } = process.env;


    emailjs.send(REACT_APP_EMAILJS_SERVICE_ID, REACT_APP_EMAILJS_TEMPLATE_ID, formData, REACT_APP_EMAILJS_USER_ID)
      .then((response) => {
      console.log('Service ID:', REACT_APP_EMAILJS_SERVICE_ID);
      console.log('Template ID:', REACT_APP_EMAILJS_TEMPLATE_ID);
      console.log('User ID:', REACT_APP_EMAILJS_USER_ID);

        console.log('Email sent successfully!', response.status, response.text);
        setSuccessMessage('Message sent successfully!');
        setErrorMessage('');
        setFormData({ name: '', email: '', message: '' }); // Clear form
      }, (error) => {
        console.error('Failed to send email:', error);
        setErrorMessage('Failed to send message. Please try again.');
        setSuccessMessage('');
      });
  };

  return (
    <>
      <div className="container contact-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={contactArray}
              idx={15}
            />
          </h1>
          <p>If you have any questions, feel free to reach out!</p>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <button type="submit" className="flat-button">
              SEND MESSAGE
            </button>
          </form>
          {successMessage && <p className="success-message">{successMessage}</p>}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
      </div>
      <Loader type="pacman" />
    </>
  );
};

export default Contact;
