import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from 'react-loaders';
import AnimatedLetters from '../components/AnimatedLetters';
import LogoTitle from '../assets/images/logo.png';
import Logo from '../components/Logo';
import Small_Logo from '../assets/images/A.png';
import Sidebar from '../components/Sidebar'; // Import your Sidebar component
import './index.scss';

const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate');
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const nameArray = ['m', 'r', 'i', 't', 'e', 's', 'h'];
  const jobArray = ['A', 'p', 'p', ' ', 'd', 'e', 'v', 'e', 'l', 'o', 'p', 'e', 'r', '.'];

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 4000);
    return () => clearTimeout(timeout);
  }, []);

  const handleToggleSidebar = () => {
    setSidebarOpen(prev => !prev);
  };

  return (
    <>
      <Sidebar onToggle={handleToggleSidebar} />
      <div className={`container home-page ${sidebarOpen ? 'sidebar-open' : ''}`}>
        <div className="text-zone">
          <h1>
            <span className={letterClass}>H</span>
            <span className={`${letterClass} _12`}>i,</span>
            <br />
            <span className={`${letterClass} _13`}>I</span>
            <span className={`${letterClass} _14`}>'m</span>
            <img src={Small_Logo} alt="Developer Name" />
            <AnimatedLetters letterClass={letterClass} strArray={nameArray} idx={15} />
            <br />
            <AnimatedLetters letterClass={letterClass} strArray={jobArray} idx={22} />
          </h1>
          <h2>Android / DSA / Java</h2>
          <Link to="/contact" className="flat-button">
            CONTACT ME
          </Link>
        </div>
        <Logo />
      </div>
      <Loader type="pacman" />
    </>
  );
};

export default Home;
