import { useEffect, useState } from 'react';
import Loader from 'react-loaders';
import AnimatedLetters from '../components/AnimatedLetters';
import './index.scss'; // Ensure this path is correct

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate');

  const aboutArray = [
    'I',
    ' a',
    'm',
    ' a',
    ' s',
    'o',
    'f',
    't',
    'w',
    'a',
    'r',
    'e',
    ' d',
    'e',
    'v',
    'e',
    'l',
    'o',
    'p',
    'e',
    'r',
    ' w',
    'i',
    't',
    'h',
    ' a',
    ' p',
    'a',
    's',
    's',
    'i',
    'o',
    'n',
    ' f',
    'o',
    'r',
    ' c',
    'r',
    'e',
    'a',
    't',
    'i',
    'v',
    'i',
    't',
    'y',
    '.',
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 4000);
    return () => clearTimeout(timeout); // Clean up the timeout
  }, []);

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={aboutArray}
              idx={15}
            />
          </h1>
          <p>
            I specialize in creating dynamic and beautiful web applications.
            My journey began with a passion for technology and a love for
            problem-solving. I enjoy working with the latest technologies to
            bring ideas to life.
          </p>
          <p>
            When I'm not coding, I love to explore new places, read books,
            and engage in outdoor activities.
          </p>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  );
};

export default About;
