// src/components/Logo.js
import React from 'react';
import LogoImage from '../assets/images/logo.png'; // Update with your logo path

const Logo = () => {
  return (
    <div className="logo">
      <img src={LogoImage} alt="Logo" />
    </div>
  );
};

export default Logo;
