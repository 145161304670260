import './index.scss';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faClose, faHome, faUser, faEnvelope, faSuitcase } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import ProfilePic from '../assets/images/logo.png';

const Sidebar = ({ onToggle }) => {
  const [showNav, setShowNav] = useState(false);

  const toggleNav = () => {
    setShowNav(prevState => !prevState);
    onToggle(); // Notify parent component to adjust main content
  };

  return (
    <div className={`nav-bar ${showNav ? 'open' : ''}`}>
      <div className="profile">
        <img src={ProfilePic} alt="Profile" className="profile-pic" />
        <h2 className="profile-name">Your Name</h2>
      </div>
      <nav>
        <div className="toggle-icon" onClick={toggleNav}>
          {showNav ? (
            <FontAwesomeIcon icon={faClose} />
          ) : (
            <FontAwesomeIcon icon={faBars} />
          )}
        </div>
        <div className={`nav-links ${showNav ? 'show' : ''}`}>
          <NavLink to="/" onClick={toggleNav}>
            <FontAwesomeIcon icon={faHome} /> Home
          </NavLink>
          <NavLink to="/about" onClick={toggleNav}>
            <FontAwesomeIcon icon={faUser} /> About
          </NavLink>
          <NavLink to="/portfolio" onClick={toggleNav}>
            <FontAwesomeIcon icon={faSuitcase} /> Portfolio
          </NavLink>
          <NavLink to="/contact" onClick={toggleNav}>
            <FontAwesomeIcon icon={faEnvelope} /> Contact
          </NavLink>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
