import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useState } from 'react';
import Sidebar from './components/Sidebar';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';

const App = () => {
  const [showNav, setShowNav] = useState(false);

  const toggleSidebar = () => {
    setShowNav(prev => !prev);
  };

  return (
    <Router>
      <Sidebar showNav={showNav} toggleSidebar={toggleSidebar} />
      <div className={`main-content ${showNav ? 'shifted' : ''}`}>
        <Routes>
          <Route path="/" element={<Home toggleSidebar={toggleSidebar} />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
